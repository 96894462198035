@media screen and (max-width: 768px) {
    .desktop-buttons {
      display: none !important;
    }
    
    .mobile-menu {
      display: block !important;
    }
  }
  
  @media screen and (min-width: 769px) {
    .desktop-buttons {
      display: flex !important;
    }
    
    .mobile-menu {
      display: none !important;
    }
  }