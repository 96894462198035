/* CustomerCreditBalance.module.css */

.ionRowContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 1rem;
}


/* Add other styles here */
.inputStyle {
	width: 50%;
	margin-top: 0.5rem;
	border-left: 1px solid #d6d6d6;
	border-top: 1px solid #d6d6d6;
	border-bottom: 1px solid #d6d6d6;
	border-bottom-left-radius: 5px;
	border-top-left-radius: 5px;
	padding-left: 20px;
}

.selectStyle {
	width: 30%;
	border: 1px solid #d6d6d6;
	border-radius: 5px;
	margin-top: 0.5rem;
}

.textAreaStyle {
	border: 1px solid #d6d6d6;
	border-radius: 5px;
	margin-bottom: 0.5rem;
	padding: 0 1rem;
}

.fullWidthStyle {
	width: '100%'
}

.updateCreditButton {
	width: 425px;
}


.ending-balance {
	font-weight: bold;
	font-size: 16px;
}

.positive-balance {
	color: green !important;
}

.negative-balance {
	color: red !important;
}

.listStyles {
	width: 80%;
}

@media screen and (max-width:768px) {
	.updateCreditButton {
		width: 90%;
	}

	.listStyles {
		width: 100%;
	}
}

@media (min-width: 576px) {
	.ionRowContainer {
		width: 100%;
	}
}

@media (min-width: 768px) {
	.ionRowContainer {
		width: 50%;
	}
}