.create-account-form {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.create-account-form.visible {
  opacity: 1;
  transform: translateY(0);
}

.back-button {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.back-button.visible {
  opacity: 1;
  transform: translateX(0);
}

/* Add smooth transitions for color selection */
.color-option {
  transition: transform 0.2s ease, border-color 0.2s ease;
}

.color-option:hover {
  transform: scale(1.05);
}

.color-option.selected {
  transform: scale(1.1);
  border-color: #000;
}

/* Add smooth transitions for form inputs */
.form-input {
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.form-input:focus {
  border-color: var(--ion-color-primary);
  box-shadow: 0 0 0 2px rgba(var(--ion-color-primary-rgb), 0.1);
} 