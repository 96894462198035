/* GlobalStyles.module.css */

/* Margin utility classes */
.m1 {
	margin: 0.25rem;
}

.m2 {
	margin: 0.5rem;
}

.m3 {
	margin: 0.75rem;
}

.m4 {
	margin: 1rem;
}

/* Margin top utility classes */
.mt1 {
	margin-top: 0.25rem;
}

.mt2 {
	margin-top: 0.5rem;
}

.mt3 {
	margin-top: 0.75rem;
}

.mt4 {
	margin-top: 1rem;
}

/* Margin right utility classes */
.mr1 {
	margin-right: 0.25rem;
}

.mr2 {
	margin-right: 0.5rem;
}

.mr3 {
	margin-right: 0.75rem;
}

.mr4 {
	margin-right: 1rem;
}

/* Margin bottom utility classes */
.mb1 {
	margin-bottom: 0.25rem;
}

.mb2 {
	margin-bottom: 0.5rem;
}

.mb3 {
	margin-bottom: 0.75rem;
}

.mb4 {
	margin-bottom: 1rem;
}

/* Margin left utility classes */
.ml1 {
	margin-left: 0.25rem;
}

.ml2 {
	margin-left: 0.5rem;
}

.ml3 {
	margin-left: 0.75rem;
}

.ml4 {
	margin-left: 1rem;
}

/* Margin utility classes for top and bottom, and left and right */
.mtb1 {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}

.mtb2 {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.mtb3 {
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
}

.mtb4 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.mlr1 {
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}

.mlr2 {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.mlr3 {
	margin-left: 0.75rem;
	margin-right: 0.75rem;
}

.mlr4 {
	margin-left: 1rem;
	margin-right: 1rem;
}

/* Padding utility classes */
.p1 {
	padding: 0.25rem;
}

.p2 {
	padding: 0.5rem;
}

.p3 {
	padding: 0.75rem;
}

.p4 {
	padding: 1rem;
}

/* Padding top utility classes */
.pt1 {
	padding-top: 0.25rem;
}

.pt2 {
	padding-top: 0.5rem;
}

.pt3 {
	padding-top: 0.75rem;
}

.pt4 {
	padding-top: 1rem;
}

/* Padding right utility classes */
.pr1 {
	padding-right: 0.25rem;
}

.pr2 {
	padding-right: 0.5rem;
}

.pr3 {
	padding-right: 0.75rem;
}

.pr4 {
	padding-right: 1rem;
}

/* Padding bottom utility classes */
.pb1 {
	padding-bottom: 0.25rem;
}

.pb2 {
	padding-bottom: 0.5rem;
}

.pb3 {
	padding-bottom: 0.75rem;
}

.pb4 {
	padding-bottom: 1rem;
}

/* Padding left utility classes */
.pl1 {
	padding-left: 0.25rem;
}

.pl2 {
	padding-left: 0.5rem;
}

.pl3 {
	padding-left: 0.75rem;
}

.pl4 {
	padding-left: 1rem;
}

/* Padding utility classes for top and bottom, and left and right */
.ptb1 {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}

.ptb2 {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.ptb3 {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
}

.ptb4 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.plr1 {
	padding-left: 0.25rem;
	padding-right: 0.25rem;
}

.plr2 {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.plr3 {
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.plr4 {
	padding-left: 1rem;
	padding-right: 1rem;
}

/* GlobalStyles.module.css */

:root {
	--width-unit: 10%;
	/* Default width unit */
}

/* Width utility classes with dynamic values */
.w10 {
	width: var(--width-unit);
}

.w20 {
	width: calc(var(--width-unit) * 2);
}

.w30 {
	width: calc(var(--width-unit) * 3);
}

.w40 {
	width: calc(var(--width-unit) * 4);
}

.w50 {
	width: calc(var(--width-unit) * 5);
}

.w60 {
	width: calc(var(--width-unit) * 6);
}

.w70 {
	width: calc(var(--width-unit) * 7);
}

.w80 {
	width: calc(var(--width-unit) * 8);
}

.w90 {
	width: calc(var(--width-unit) * 9);
}

.w100 {
	width: 100%;
}