/* CalendarView5Header.css */

.toolbar-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid #d7d7d7;
}

.week-dates-container {
	display: flex;
	align-items: center;
	padding-bottom: 5px;
	width: 100%;
}

/* Hide the column on mobile devices */
.desktop-tablet-only {
	display: block;
}

@media (max-width: 768px) {
	.desktop-tablet-only {
		display: none;
	}
}

@media (min-width: 767px) {
	.week-dates-container {
		align-self: center;
		width: 50%;
	}
}

.day-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	padding: 5px 0;
}

.arrow-icon {
	cursor: pointer;
	color: black;
	font-size: 20px;
}

.circle-button {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 6px;
	border-radius: 50%;
	width: 38px;
	height: 38px;
	font-weight: bold;
	font-size: 20px;
	text-align: center;
}

.circle-button.active {
	background-color: #3880ff;
	color: white;
	/* Example active background color */
}

.date-label {
	font-weight: bold;
	font-size: 12px;
}

.weekday-label {
	color: #b6b6b6;
}

.active-weekday-label {
	color: white;
}

.grey-day button {
	color: #b6b6b6 !important;
	/* or any other grey color you prefer */
}