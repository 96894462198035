/* First, let's clean up by removing conflicting styles */
.yours, .mine, .chat {
	display: none; /* Hide old styles */
}

/* Update message container styles */
.message-wrap {
	flex: 1;
	padding: 0 14px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	background: #fff;
	justify-content: flex-end; /* Push content to bottom */
	min-height: 0; /* Important for flex scrolling */
}

/* Message group container */
.message-group {
	display: flex;
	flex-direction: column;
	width: 100%;
}

/* Date separator styles */
.date-separator {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 12px 0;
	position: relative;
}

.date-text {
	/* background: #E5E5EA; */
	color: #666;
	font-size: 0.70em;
	padding: 4px 12px;
	border-radius: 13px;
	/* text-transform: uppercase; */
	letter-spacing: 0.1em;
	font-weight: 500;
}

/* Message styles */
.message {
	position: relative;
	padding: 12px 16px;
	border-radius: 20px;
	margin: 4px 0 20px;
	max-width: 70%;
	word-wrap: break-word;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

/* Sent message styles (User messages) */
.message.sent {
	background: #007AFF;
	color: white;
	margin-left: auto; /* This will push the message to the right */
	border-bottom-right-radius: 4px;
}

/* Received message styles (Customer messages) */
.message.received {
	background: #E5E5EA;
	color: black;
	margin-right: auto; /* This will keep the message on the left */
	border-bottom-left-radius: 4px;
}

/* Message timestamp styles */
.message-time {
	position: absolute;
	bottom: -18px;
	font-size: 0.75em;
	color: #8e8e93;
	white-space: nowrap; /* Prevent timestamp from wrapping */
}

/* Adjust timestamp alignment based on message type */
.message.sent .message-time {
	right: 0; /* Align to right edge of message */
	padding-right: 2px;
}

.message.received .message-time {
	left: 0; /* Align to left edge of message */
	padding-left: 2px;
}

/* Remove the previous timestamp color overrides */
.sent .message-time,
.received .message-time {
	color: #8e8e93 !important; /* Using !important to override any existing styles */
}

/* Message input area styles */
.message-footer {
	padding: 10px 15px;
	background: white;
	border-top: 1px solid #eee;
	z-index: 1000;
	flex-shrink: 0;
}

.message-input-container {
	display: flex;
	align-items: flex-end;
	gap: 8px;
	border: 1px solid #e0e0e0;
	border-radius: 20px;
	background: white;
	padding: 4px 8px;
	min-height: 36px;
}

.message-input {
	--padding-top: 8px;
	--padding-bottom: 8px;
	--padding-start: 12px;
	--padding-end: 8px;
	--background: transparent;
	--placeholder-color: #8e8e93;
	--placeholder-opacity: 1;
	margin: 0;
	flex: 1;
	border: none;
	min-height: 24px;
	max-height: 120px;
	overflow-y: auto;
	resize: none;
	line-height: 1.4;
}

/* Style the inner input to allow multi-line */
.message-input::part(native) {
	min-height: 24px;
	height: auto;
	white-space: pre-wrap;
}

/* Update send button styles */
.send-button {
	--padding-start: 0;
	--padding-end: 0;
	--padding-top: 0;
	--padding-bottom: 0;
	--box-shadow: none;
	--border-radius: 50%;
	--background: #007AFF;
	--background-activated: #0056b3;
	--background-focused: #0056b3;
	--background-hover: #0056b3;
	width: 32px !important;
	height: 32px !important;
	margin: 0;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.2s ease, visibility 0.2s ease;
	border-radius: 50%;
	display: none;
	padding: 0;
	min-height: 32px;
}

.send-button::part(native) {
	padding: 0;
	width: 32px;
	height: 32px;
	border-radius: 50%;
}

/* Show button only when there's text */
.message-input-container:has(ion-input[value]:not([value=""])) .send-button {
	opacity: 1;
	visibility: visible;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Remove previous disabled state styles */
.send-button.button-disabled {
	opacity: 0;
	visibility: hidden;
	display: none;
}

.send-button ion-icon {
	font-size: 18px;
	transform: rotate(-45deg);
	color: white;
	margin: 0;
	padding: 0;
}

/* Loading spinner color */
.send-button ion-spinner {
	color: white;
}

/* Remove any conflicting styles */
.message-footer ion-row,
.message-footer ion-col {
	display: none;
}

.message-container {
	display: flex;
	align-items: center;
}

.message-label {
	padding: 10px;
	background-color: #d6d6d6;
	border-radius: 50%;
}

.last {
	margin-bottom: 6rem;
}

.no-conversation {
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #f9f9f9;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 60%;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #c2c2c2 #f8f8f8;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 6px;
}

*::-webkit-scrollbar-track {
	background: #090909;
}

*::-webkit-scrollbar-thumb {
	background-color: #c2c2c2;
	border-radius: 58px;
	border: 3px solid #222428;
}

.dots {
	height: 5px;
	width: 5px;
	background-color: grey;
	border-radius: 50%;
	margin: 5px 2px;
}

.placeholder-container {
	opacity: 0.7;
}

.dots-placeholder {
	display: flex;
}

.latest-message {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #9f9f9f;
}

.send-message {
	position: fixed;
	width: 100%;
	bottom: 0;
	box-sizing: border-box;
	width: "100%";
	background-color: white;
}

/* new css for messages */

.container {
	display: flex;
	height: 100%;
}

.content {
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
}

sidebar {
	width: 300px;
	min-width: 300px;
	display: flex;
	background: #fff;
	flex-direction: column;
	border-right: 1px solid #ccc;
	transition: 500ms all;
}

sidebar .logo {
	display: flex;
	margin: 10px 0 0 0;
	padding-bottom: 10px;
	align-items: center;
	justify-content: center;
	color: #000;
	font-size: 3em;
	letter-spacing: 7px;
	border-bottom: 1px solid #ccc;
}

sidebar .list-wrap {
	width: 100%;
	overflow: auto;
}

sidebar .list-wrap .list {
	border-bottom: 1px solid #ccc;
	background: #fff;
	display: flex;
	align-items: center;
	padding: 5px;
	height: 70px;
	cursor: pointer;
}

sidebar .list-wrap .list:hover,
sidebar .list-wrap .list.active {
	background: #F4F7F9;
}

sidebar .list-wrap .list img {
	border-radius: 50%;
	width: 50px;
	height: 50px;
	object-fit: cover;
	margin-right: 10px;
	box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
}

sidebar .list-wrap .list .info {
	margin-left: 10px;
	flex: 1;
	overflow: hidden;
}

sidebar .list-wrap .list .info .user {
	font-weight: 700;
}

sidebar .list-wrap .list .info .text {
	margin-top: 3px;
	font-size: 0.85em;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #9f9f9f;
}

sidebar .list-wrap .list .time {
	margin-right: 5px;
	margin-left: 5px;
	font-size: 0.75em;
	color: #a9a9a9;
}

sidebar .list-wrap .list .count {
	font-size: 0.75em;
	background: #bde2f7;
	box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.7);
	padding: 3px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	text-align: center;
	color: #000;
}

.content header {
	height: 76px;
	background: #fff;
	border-bottom: 1px solid #ccc;
	display: flex;
	padding: 10px;
	align-items: center;
}

.content header img {
	border-radius: 50%;
	width: 50px;
	height: 50px;
	object-fit: cover;
	margin-right: 10px;
	box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
}

.content header .info {
	flex: 1;
}

.content header .info .user {
	font-weight: 700;
}

.content header .info .time {
	display: flex;
	margin-top: 3px;
	font-size: 0.85em;
}

.content header .open {
	display: none;
}

.content header .open a {
	color: #000;
	letter-spacing: 3px;
}

/* Remove the ::before pseudo-element that was pushing content up */
.message-wrap::before {
	display: none;
}

.message-wrap .message-list {
	align-self: flex-start;
	max-width: 70%;
}

.message-wrap .message-list.me {
	align-self: flex-end;
}

.message-wrap .message-list.me .msg {
	background: #bde2f7;
	color: #111;
}

.message-wrap .message-list .msg {
	background: #f2f2f2;
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 5px;
}

.message-wrap .message-list .time {
	text-align: right;
	color: #999;
	font-size: 0.75em;
}

.divider {
	display: flex;
	align-items: center;
	justify-content: center;
}

.line {
	flex-grow: 1;
	height: 1px;
	background-color: #ccc;
	/* You can choose any color */
	margin: 0 10px;
	/* Adjust space around the text */
}

.divider-text {
	white-space: nowrap;
	color: #8b8b8b;

	/* Prevents the text from wrapping */
}

@media only screen and (max-width: 480px),
only screen and (max-width: 767px) {
	sidebar {
		width: 100%;
		padding: 1rem;
		/* position: absolute;
		width: 100%;
		min-width: 100%;
		height: 0vh;
		bottom: 0;
		box-shadow: 0 5px 25px -5px black; */
	}

	/* sidebar.opened {
		height: 70vh !important;
	}

	sidebar .logo {
		display: none;
	}

	sidebar .list-wrap .list .count {
		font-size: 0.75em;
	}

	header .open {
		display: block !important;
	} */
}

/* Ensure proper spacing between groups */
.message-wrap > div > div:not(:first-child) {
	margin-top: 8px;
}

/* Add animation for new messages */
@keyframes slideIn {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

/* Add animation class */
.message.new-message {
	animation: slideIn 0.3s ease-out forwards;
}