/* .fc-toolbar {
	background-color: #f8f9fa;
}

.fc-dayGridMonth-view,
.fc-timeGridWeek-view,
.fc-timeGridDay-view {
	background-color: #ffffff;
}

.fc .fc-col-header-cell-cushion {
	color: black
}

.fc .fc-toolbar-title {
	font-size: 18px;
	margin: 1rem 0;
}

.fc .fc-button .fc-icon {
	font-size: 20px;
}

.fc .fc-button {
	padding: 2px 5px;
}


.fc-direction-ltr .fc-daygrid-more-link {
	float: right;
	font-size: 12px;
}




.fc-scrollgrid thead {
	position: sticky;
	top: 0;
}

.fc-license-message {
	display: none;
}

.fc-timegrid-event {
	margin-bottom: unset !important;
}

.fc-timegrid-event .fc-event-main {
	padding: 0;
}

.fc-direction-ltr .fc-timegrid-col-events {
	margin: 0 10px;
}

.fc-v-event {
	background-color: unset !important;
	border: unset !important;
}

.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
	color: gray;
	font-size: 12px;
}

.fc .fc-daygrid-day.fc-day-today,
.fc .fc-timegrid-col.fc-day-today {
	background-color: unset;
}

.fc .fc-timegrid-slot-label {
	border: none;
}

.fc .fc-scrollgrid {
	border: none;
}

.fc-scrollgrid-section.fc-scrollgrid-section-body td[role="presentation"]:first-child {
	border: none;
}

.fc .fc-timegrid-slot {
	height: 3.5em;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
	text-align: right;
	display: flex;
	align-items: start;
	height: 100%;
	margin-bottom: 15px;
	margin-left: 5px;
}

.fc .fc-toolbar.fc-header-toolbar {
	background-color: white;
	margin: 1rem;
} */

/* =========================================================================================================================================================== */
/* new css */
.rbc-time-column .rbc-timeslot-group {
	min-height: 3rem;
}

.rbc-time-content>.rbc-day-slot {
	background: "white";
}

button.circle-button {
	border-radius: 50%;
	background-color: unset;
	width: 25px;
	height: 25px;
	color: black;
	font-size: 20px;
}

.week-dates-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

/* Hide the column on mobile devices */
.desktop-tablet-only {
	display: block;
}

@media (max-width: 768px) {
	.desktop-tablet-only {
		display: none;
	}
}

button.circle-button.active {
	background-color: #3880ff;
	color: white;
}

/* .rbc-time-view .rbc-allday-cell {
	display: none;
} */

.rbc-time-header.rbc-overflowing {
	margin: unset;
}

.rbc-time-content>.rbc-day-slot {
	background-color: white;
}

.rbc-time-gutter .rbc-timeslot-group {
	border-bottom: unset;
}


.rbc-time-content {
	border-top: unset !important;
	padding: 10px 0;
}

ion-icon.arrow-icon.ios {
	width: 25px;
	height: 25px;
}

.rbc-day-slot .rbc-event-label {
	display: none;
	font-size: 10px;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
	margin: 5px;
	min-height: 15px !important
}

.rbc-day-slot .rbc-event-content {
	display: flex;
	align-items: center;
}

.rbc-calendar {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	background-color: white;
	width: 100%;
}


.calendar-container {
	background-color: white;
	width: 100%;
}

.rbc-label {
	margin-top: -10px;
	color: grey;
	font-size: 14px;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
	border-right: unset !important;
}

.rbc-header {
	border-bottom: unset !important;
	padding: 0.5rem !important;
	font-size: 1.2rem !important;
}


.rbc-time-view {
	border: unset !important;
}

.toolbar-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.rbc-time-content>*+*>* {
	border-left: unset !important;
}

.rbc-events-container {
	border-left: 1px solid #dbdbdb !important;
}

.rbc-time-slot {
	border: unset !important;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
	min-width: 4rem;
}

.rbc-time-header {
	position: sticky;
	top: 6rem;
	z-index: 999;
	background: white;
}

.break-event {
	border-radius: 4px !important;
	background: repeating-linear-gradient(132deg, #D9D6FE99, #D9D6FE99 5px, rgb(227 183 255 / 25%) 1px, rgb(255 255 255 / 25%) 8px) !important
}

.break-event::before {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	border-radius: 4px !important;
	width: 100% !important;
	height: 100% !important;
	content: '' !important;

}

.month-break-event {
	position: relative !important;
	border-radius: 4px !important;
	background: repeating-linear-gradient(132deg, #D9D6FE99, #D9D6FE99 5px, rgb(227 183 255 / 25%) 1px, rgb(255 255 255 / 25%) 8px) !important;
	width: 100% !important;

}

.month-break-event::before {
	top: 0 !important;
	left: 0 !important;
	border-radius: 4px !important;
	width: 100% !important;
	height: 100% !important;
	content: '' !important;

}

/* .rbc-time-content {
	height: 100vh;
	max-height: 100vh;
	min-height: 100vh;
} */
/* .rbc-time-content {
	height: 100vh;
	min-height: 100vh;
} */

/* Media query for desktop */
@media screen and (max-width: 768px) {

	/* Default style for mobile */
	.rbc-time-view-resources .rbc-day-slot,
	.rbc-time-view-resources .rbc-time-header-content {
		min-width: 65% !important;
	}
}

/* Media query for desktop */
@media screen and (min-width: 768px) {
	.toolbar-header {
		width: 65%;
	}

	.calendar-container {
		padding: 2rem;
	}

	.rbc-time-view-resources .rbc-day-slot,
	.rbc-time-view-resources .rbc-time-header-content {
		min-width: 25% !important;
	}
}

.mainContainer {
	width: 100%;

}

@media (max-width: 768px) {
	.mainContainer {
		width: 100%;
		max-height: 100%;
		overflow-x: auto;
		overflow-y: auto;
	}
}

/* Hide the desktop view on mobile devices */
.mobile-view {
	display: block;
	/* Show on mobile */
}

.desktop-view {
	display: none;
	/* Hide on mobile */
}

/* Hide the mobile view on desktop devices */
@media (min-width: 768px) {
	.mobile-view {
		display: none;
		/* Hide on desktop */
	}

	.desktop-view {
		display: block;
		/* Show on desktop */
	}
}

/* ======================================================= */


.column {
	flex: 1;
	border: 1px solid #ccc;
	padding: 10px;
	overflow-y: auto;
	/* Enable vertical scrolling */
}

.scrollable-content {
	height: 200%;
}
.canellation-policy {
  position: absolute;
  top: -6rem;
  right: 8%;
  border-radius: 10px;
  background-color: black;
  padding: 20px;
  width: 10rem;
  height: 75px;
  color: #fff;
}

.canellation-policy.arrow-bottom:after {
  position: absolute;
  right: 65px;
  bottom: -10px;
  border-top: 10px solid black;
  border-right: 10px solid transparent;
  border-bottom: none;
  border-left: 10px solid transparent;
  content: " ";
}

