/* GlobalStyles.module.css */

/* Display utility classes */
.displayFlex {
	display: flex;
}

.displayInlineFlex {
	display: inline-flex;
}

/* Justify-content utility classes */
.justifyCenter {
	justify-content: center;
}

.justifyStart {
	justify-content: start;
}

.justifyEnd {
	justify-content: end;
}

.justifySpaceBetween {
	justify-content: space-between;
}

.justifySpaceAround {
	justify-content: space-around;
}

.justifySpaceEvenly {
	justify-content: space-evenly;
}

.flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Flex-direction utility classes */
.flexRow {
	flex-direction: row;
}

.flexRowReverse {
	flex-direction: row-reverse;
}

.flexColumn {
	flex-direction: column;
}

.flexColumnReverse {
	flex-direction: column-reverse;
}

/* Align-items utility classes */
.alignCenter {
	align-items: center;
}

.alignStart {
	align-items: flex-start;
}

.alignEnd {
	align-items: flex-end;
}

.alignBaseline {
	align-items: baseline;
}

.alignStretch {
	align-items: stretch;
}

.inputRoundedStyleNoPadding {
	border: 1px solid #d6d6d6;
	border-radius: 5px;
}

.inputStyle {
	border: 1px solid #d6d6d6;
	border-radius: 5px;
	padding: 0 10px !important;
}

.inputTextAreaStyle {
	border: 1px solid #d6d6d6;
	border-radius: 5px;
	padding: 0 15px !important;
}

.roundedInputStyle {
	border: 1px solid #242424;
	border-radius: 10px;
	padding: 10px 20px !important;
}