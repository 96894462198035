* {
	font-family: 'Figtree';
}

/* Default styles (mobile-first approach) */
.desktop-button {
	display: none;
	/* Hide desktop button by default */
}

.mobile-button {
	display: inline-flex;
	/* Show mobile button by default */
}

/* Media query for desktop view */
@media (min-width: 768px) {
	.desktop-button {
		display: inline-flex;
		/* Show desktop button on larger screens */
	}

	.mobile-button {
		display: none;
		/* Hide mobile button on larger screens */
	}
}